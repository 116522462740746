import React, { useContext, useState, useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { useParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader, CardActions } from "@mui/material";
import { post } from "aws-amplify/api";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { UserContext } from "../../UserContext";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
const AddMembersList = ({
    tenantId,
    groupId,
    innerRef,
    currentMembers,
    parentState,
    setParentState,
}) => {
    const [formState, setFormState] = useState({
        tenantMembers: [],
        isFetching: false,
        isFetched: false,
        isSubmitting: false,
        isSubmitted: false,
        membersToAdd: [],
    });
    const navigate = useNavigate();

    const fetchTenantMembers = async () => {
        setFormState({ ...formState, isFetching: true });
        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/tenant_users/list",
                options: {
                    queryParams: { tenantId, id: groupId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setFormState({
                ...formState,
                tenantMembers: json,
                isFetching: false,
                isFetched: true,
            });
        } catch (error) {
            navigate("/oops");
        }
    };

    if (!formState.isFetching && !formState.isFetched) {
        fetchTenantMembers();
    }

    const addGroupMembers = async () => {
        setFormState({ ...formState, isSubmitting: true });
        try {
            const restOperation = post({
                apiName: "user_management",
                path: "/user_groups/create",
                options: {
                    body: {
                        groupId,
                        tenantId,
                        userIds: formState.membersToAdd,
                    },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const response = await restOperation.response;
            await response.body.json();
            setFormState({
                ...formState,
                isSubmitting: false,
                isSubmitted: true,
            });
            setParentState({
                ...parentState,
                isAddingMembers: false,
                isFetchedMembers: false,
            });
        } catch (error) {
            navigate("/oops");
        }
    };

    const handleCheckboxClick = ({ member }) => {
        if (!member) return;

        if (formState.membersToAdd.includes(member.user.id)) {
            setFormState({
                ...formState,
                membersToAdd: formState.membersToAdd.filter(
                    (id) => id !== member.user.id
                ),
            });
            return;
        }
        setFormState({
            ...formState,
            membersToAdd: [...formState.membersToAdd, member.user.id],
        });
    };
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bgcolor="rgba(0, 0, 0, 0.5)"
            zIndex="1300"
        >
            <div ref={innerRef}>
                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CardHeader title={<b>Add Members</b>} />
                            {formState.isFetching && <CircularProgress />}
                            {formState.isFetched && (
                                <CardActions>
                                    {formState.tenantMembers.map((member) => {
                                        if (
                                            currentMembers.some(
                                                (currentMember) =>
                                                    currentMember.user.id ===
                                                    member.user.id
                                            )
                                        ) {
                                            return null;
                                        }
                                        const fullName = `${member.user.firstName} ${member.user.lastName}`;
                                        return (
                                            <MenuItem
                                                key={member.user.id}
                                                value={fullName}
                                                onClick={() =>
                                                    handleCheckboxClick({
                                                        member,
                                                    })
                                                }
                                            >
                                                <Checkbox
                                                    checked={formState.membersToAdd.includes(
                                                        member.user.id
                                                    )}
                                                />
                                                <ListItemText
                                                    primary={fullName}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                    {formState.isSubmitting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                addGroupMembers();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </CardActions>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </div>
        </Box>
    );
};

export default function OrganizationGroup() {
    const { tenantId, groupId } = useParams();
    const [state, setState] = useState({
        group: {},
        members: [],
        isFetchingGroup: false,
        isFetchedGroup: false,
        isFetchingMembers: false,
        isFetchedMembers: false,
        isAddingMembers: false,
    });
    const navigate = useNavigate();

    const { user } = useContext(UserContext);
    if (
        user.isFetchedGroups &&
        !user.groups.some(
            (group) =>
                group.group.name === "Administrators" &&
                group.tenant.name === "clipr"
        )
    ) {
        navigate("/oops");
    }

    const inviteUserRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inviteUserRef.current &&
                !inviteUserRef.current.contains(event.target)
            ) {
                setState((prevState) => ({
                    ...prevState,
                    isAddingMembers: false,
                }));
            }
        };

        if (state.isAddingMembers) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [state.isAddingMembers]);
    const fetchGroup = async () => {
        setState({ ...state, isFetchingGroup: true });
        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/tenant_groups/get",
                options: {
                    queryParams: { tenantId, id: groupId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setState({
                ...state,
                group: json,
                isFetchingGroup: false,
                isFetchedGroup: true,
            });
        } catch (error) {
            navigate("/oops");
        }
    };

    if (!state.isFetchingGroup && !state.isFetchedGroup) {
        fetchGroup();
    }

    const fetchMembers = async () => {
        setState({ ...state, isFetchingMembers: true });
        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/user_groups/list",
                options: {
                    queryParams: { groupId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setState({
                ...state,
                members: json,
                isFetchingMembers: false,
                isFetchedMembers: true,
            });
        } catch (error) {
            navigate("/oops");
        }
    };

    if (!state.isFetchingMembers && !state.isFetchedMembers) {
        fetchMembers();
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {state.isAddingMembers && (
                <AddMembersList
                    tenantId={tenantId}
                    groupId={groupId}
                    innerRef={inviteUserRef}
                    currentMembers={state.members}
                    parentState={state}
                    setParentState={setState}
                />
            )}
            <Grid container spacing={2}>
                <Grid size={11}>
                    <Box display="flex" justifyContent="center">
                        {!state.isFetchedGroup ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="h2">
                                <b>
                                    {`${state.group.tenant.name} - ${state.group.name}`}
                                </b>
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid size={1}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        marginTop="3vh"
                        marginRight="1vw"
                    >
                        <Button
                            onClick={() => {
                                setState({ ...state, isAddingMembers: true });
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Add
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {!state.isFetchedGroup && !state.isFetchedMembers ? (
                <CircularProgress />
            ) : (
                <Box display="flex" justifyContent="center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center">
                                {state.members.length > 0 && (
                                    <Typography variant="h6">
                                        {state.members.map((member, index) => (
                                            <div key={index}>
                                                {member.user.email}
                                            </div>
                                        ))}
                                    </Typography>
                                )}
                                {state.members.length === 0 && (
                                    <Typography variant="h6">
                                        No members in this group
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
