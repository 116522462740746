import { v4 as uuidv4 } from "uuid";

const handleMouseDown = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    editingMode,
    fieldDimensions,
    lasooSelection,
    setLasooSelection,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    anchoredPreviewedLine,
    direction,
    losLocation,
    hoveredId,
}) => {
    if (!state.editing) return;
    if (e.evt.button === 2) return; // Ignore right clicks

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    if (state.editingMode === "addingIcon") {
        const lastShapes = state.shapes;
        setUndoStack([
            ...undoStack,
            {
                shapes: lastShapes,
                direction: direction.current,
                losLocation,
                formation: state.formation,
                play: state.play,
                vsFormation: state.vsFormation,
                vsPlay: state.vsPlay,
                notes: state.notes,
            },
        ]);
        setRedoStack([]);
        setState({
            ...state,
            shapes: [...state.shapes, { ...previewedShape, id: uuidv4() }],
        });
    } else if (state.editingMode === "addingLine") {
        if (hoveredId) {
            const hoveredShape = state.shapes.find(
                (shape) => shape.id === hoveredId
            );
            if (hoveredShape) {
                alert("addingLine on another shape");
                isAddingLine.current = false;
                anchoredPreviewedLine.current = false;
                setPreviewedShape(null);
            }
        }

        isDrawing.current = true;

        if (isAddingLine.current === true) {
            setUndoStack([
                ...undoStack,
                {
                    shapes: state.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: state.formation,
                    play: state.play,
                    vsFormation: state.vsFormation,
                    vsPlay: state.vsPlay,
                    notes: state.notes,
                },
            ]);
            setRedoStack([]);
            setState({
                ...state,
                shapes: [
                    ...state.shapes.slice(0, -1),
                    {
                        ...state.shapes[state.shapes.length - 1],
                        points: [
                            ...state.shapes[state.shapes.length - 1].points,
                            (mousePos.x - stagePosition.x) *
                                (65 / fieldDimensions.width),
                            (mousePos.y - stagePosition.y) *
                                (150 / fieldDimensions.height),
                        ],
                    },
                ],
            });

            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -4)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        } else {
            const lastShapes = state.shapes;
            isAddingLine.current = true;
            setUndoStack([
                ...undoStack,
                {
                    shapes: lastShapes,
                    direction: direction.current,
                    losLocation,
                    formation: state.formation,
                    play: state.play,
                    vsFormation: state.vsFormation,
                    vsPlay: state.vsPlay,
                    notes: state.notes,
                },
            ]);
            setRedoStack([]);
            setState({
                ...state,

                shapes: [
                    ...state.shapes,
                    {
                        shape: "line",
                        head: state.addingVariant.head,
                        dashed: state.addingVariant.dashed,
                        jagged: state.addingVariant.jagged,
                        id: uuidv4(),
                        selected: false,
                        color: "black",
                        radius: 0.25,
                        points: [
                            (mousePos.x - stagePosition.x) *
                                (65 / fieldDimensions.width),
                            (mousePos.y - stagePosition.y) *
                                (150 / fieldDimensions.height),
                        ],
                    },
                ],
            });
            setPreviewedShape({
                shape: "line",
                head: state.addingVariant.head,
                dashed: state.addingVariant.dashed,
                jagged: state.addingVariant.jagged,
                id: uuidv4(),
                selected: false,
                color: "black",
                radius: 0.25,
                points: [
                    (mousePos.x - stagePosition.x) *
                        (65 / fieldDimensions.width),
                    (mousePos.y - stagePosition.y) *
                        (150 / fieldDimensions.height),
                    (mousePos.x - stagePosition.x) *
                        (65 / fieldDimensions.width),
                    (mousePos.y - stagePosition.y) *
                        (150 / fieldDimensions.height),
                ],
            });
            anchoredPreviewedLine.current = true;
        }
    } else if (state.editingMode === "selecting") {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        setLasooSelection({
            isSelecting: true,
            startPos: { x, y },
            selectionRect: { x, y, width: 0, height: 0 },
        });
    }
};

const handleMouseMove = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    hoveredId,
    setHoveredId,
    editingMode,
    fieldDimensions,
    lasooSelection,
    setLasooSelection,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    anchoredPreviewedLine,
}) => {
    if (!state.editing) {
        return;
    }

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    const shape = stage.getIntersection(stage.getPointerPosition());
    if (shape && shape.id() !== hoveredId) {
        setHoveredId(shape.id());
    } else if (!shape && hoveredId) {
        setHoveredId(null);
    }
    if (state.editingMode === "addingIcon") {

        const x = (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width);
        const y =
            (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height);
        setPreviewedShape({
            ...previewedShape,
            x,
            y,
        });
    } else if (state.editingMode === "addingLine") {
        if (isDrawing.current) {
            /// free hand drawing
            let lastLine = state.shapes[state.shapes.length - 1];
            lastLine.points = lastLine.points.concat([
                (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width),
                (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height),
            ]);
            state.shapes.splice(state.shapes.length - 1, 1, lastLine);
            setState({
                ...state,
                shapes: state.shapes,
            });
            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -4)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        } else if (anchoredPreviewedLine.current) {
       
            setPreviewedShape({
                ...previewedShape,
                points: previewedShape.points
                    .slice(0, -2)
                    .concat([
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ]),
            });
        }
    } else if (
        state.editingMode === "selecting" &&
        lasooSelection.isSelecting
    ) {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        const newWidth = x - lasooSelection.startPos.x;
        const newHeight = y - lasooSelection.startPos.y;
        setLasooSelection({
            ...lasooSelection,
            selectionRect: {
                x: newWidth < 0 ? x : lasooSelection.startPos.x,
                y: newHeight < 0 ? y : lasooSelection.startPos.y,
                width: Math.abs(newWidth),
                height: Math.abs(newHeight),
            },
        });
    }
    return;
};

const handleMouseUp = ({
    e,
    state,
    setState,
    isDrawing,
    editingMode,
    lasooSelection,
    setLasooSelection,
    fieldDimensions,
    redoStack,
    setRedoStack,
    contextMenu,
    previewedShape,
    setPreviewedShape,
    hoveredId,
}) => {
    if (!state.editing) return;
    if (e.evt.button === 2) return; // Ignore right clicks

    if (state.editing && editingMode === "selecting") {
        if (!lasooSelection.isSelecting) {
            const stage = e.target.getStage();
            const shape = stage.getIntersection(stage.getPointerPosition());
            if (!shape || !shape.id()) {
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        return { ...s, selected: false };
                    }),
                });
            }
        } else if (!contextMenu) {
            const { x, y, width, height } = lasooSelection.selectionRect;
            const scaledX = x * (65 / fieldDimensions.width);
            const scaledY = y * (150 / fieldDimensions.height);
            const scaledWidth = width * (65 / fieldDimensions.width);
            const scaledHeight = height * (150 / fieldDimensions.height);

            const selectedShapes = state.shapes.filter((shape) => {
                if (
                    shape.shape === "circle" ||
                    shape.shape === "rect" ||
                    shape.shape === "triangle"
                ) {
                    return (
                        shape.x > scaledX &&
                        shape.x < scaledX + scaledWidth &&
                        shape.y > scaledY &&
                        shape.y < scaledY + scaledHeight
                    );
                } else if (shape.shape === "line") {
                    return shape.points.some(
                        (point, index) =>
                            index % 2 === 0 &&
                            point > scaledX &&
                            point < scaledX + scaledWidth &&
                            shape.points[index + 1] > scaledY &&
                            shape.points[index + 1] < scaledY + scaledHeight
                    );
                } else {
                    return false; // should never reach here
                }
            });
            setState({
                ...state,
                shapes: state.shapes.map((shape) => {
                    return {
                        ...shape,
                        selected: selectedShapes.some(
                            (selectedShape) => selectedShape.id === shape.id
                        ),
                    };
                }),
            });
        }
        setLasooSelection({
            startPos: null,
            isSelecting: false,
            selectionRect: null,
        });
    } else if (
        state.editing &&
        editingMode === "addingLine" &&
        isDrawing.current
    ) {
        isDrawing.current = false;
    }
};

export { handleMouseDown, handleMouseMove, handleMouseUp };
