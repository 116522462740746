import { Circle, Rect, Line, Arrow, Text, RegularPolygon } from "react-konva";
import { useTheme } from "@mui/material/styles";
import { useState, useCallback } from "react";
import { handleDragStart, handleDragMove, handleDragEnd } from "./handleDrag";

// import SelectionBox from "./SelectionBox";

const Drawing = ({
    fieldDimensions,
    state,
    setState,
    hoveredId,
    editingMode,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    previewedShape,
    setPreviewedShape,
    contextMenu,
    setContextMenu,
    losLocation,
    setLosLocation,
    direction,
}) => {
    const theme = useTheme();
    const [draggingShapeId, setDraggingShapeId] = useState(null); // Track which shape is being dragged
    const [dragOrigin, setDragOrigin] = useState(null);

    const onClick = useCallback(
        ({ e, shape }) => {
            if (e.evt.button === 2) return; // Ignore right clicks

            setContextMenu(null); // Clear the context menu if left-clicked
            if (e.evt.ctrlKey) {
                shape.selected = !shape.selected;
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        if (s.id === shape.id) {
                            return shape;
                        }
                        return s;
                    }),
                });
            } else {
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        if (s.id === shape.id) {
                            return { ...shape, selected: true };
                        }
                        return { ...s, selected: false };
                    }),
                });
            }
        },
        [setContextMenu, state, setState]
    );

    const onContextMenu = useCallback(
        ({ e, shape }) => {
            // Ensure context menu state is updated properly
            setContextMenu({
                x: e.evt.clientX,
                y: e.evt.clientY,
                shape,
            });
        },
        [setContextMenu]
    );

    const renderShape = ({
        shape,
        fill,
        draggable,
        undoStack,
        setUndoStack,
        redoStack,
        setRedoStack,
        isPreviewed,
    }) => {
        if (!shape) return;

        const isSelectedorHovered =
            (state.editing && shape.selected) || shape.id === hoveredId;


            if (shape.shape === "circle") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    {shape.label && (
                        <Text
                            x={x}
                            y={y}
                            text={shape.label}
                            fontSize={
                                shape.label.length === 1
                                    ? radius * 1.8
                                    : radius * 1.1
                            }
                            fill={"black"}
                            fontStyle="bold"
                            offsetX={
                                shape.label.length === 1
                                    ? radius / 1.2
                                    : radius / 1.1
                            }
                            offsetY={
                                shape.label.length === 1
                                    ? radius / 1.4
                                    : radius / 2.2
                            }
                        />
                    )}
                    <Circle
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                setRedoStack,
                                redoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        radius={radius}
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={{
                            x: -radius,
                            y: 0,
                        }}
                        fillLinearGradientEndPoint={{
                            x: radius,
                            y: 0,
                        }}
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : []
                        }
                        onClick={(e) => state.editing && onClick({ e, shape })}
                        onContextMenu={(e) =>
                            state.editing && onContextMenu({ e, shape })
                        }
                    />
                </>
            );
        } else if (shape.shape === "rect") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    {shape.label && (
                        <Text
                            x={x}
                            y={y}
                            text={shape.label}
                            fontSize={
                                shape.label.length === 1
                                    ? radius * 1.8
                                    : radius * 1.1
                            }
                            fill={"black"}
                            fontStyle="bold"
                            offsetX={
                                shape.label.length === 1
                                    ? -radius / 7.5
                                    : -radius / 10
                            }
                            offsetY={
                                shape.label.length === 1
                                    ? -radius / 3
                                    : -radius / 1.7
                            }
                        />
                    )}
                    <Rect
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        width={radius * 2}
                        height={radius * 2}
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={
                            (shape.fill === "left" ||
                                shape.fill === "middle" ||
                                shape.fill === "right") && {
                                x: 0,
                                y: 0,
                            }
                        }
                        fillLinearGradientEndPoint={
                            (shape.fill === "left" ||
                                shape.fill === "middle" ||
                                shape.fill === "right") && {
                                x: radius * 2,
                                y: 0,
                            }
                        }
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : null
                        }
                        onClick={(e) => onClick({ e, shape })}
                        onContextMenu={(e) => onContextMenu({ e, shape })}
                    />
                </>
            );
        } else if (shape.shape === "triangle") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            const radius = (shape.radius * fieldDimensions.width) / 65;

            return (
                <>
                    {shape.label && (
                        <Text
                            x={x}
                            y={y}
                            text={shape.label}
                            fontSize={
                                shape.label.length === 1
                                    ? radius * 1.4
                                    : radius * 0.9
                            }
                            fill={"black"}
                            fontStyle="bold"
                            offsetX={
                                shape.label.length === 1
                                    ? radius / 1.5
                                    : radius / 1.33
                            }
                            offsetY={
                                direction.current === "north"
                                    ? shape.label.length === 1
                                        ? radius / 1.6
                                        : radius / 6
                                    : shape.label.length === 1
                                    ? radius / 1.6
                                    : radius / 2
                            }
                        />
                    )}
                    <RegularPolygon
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        rotationDeg={direction.current === "south" ? 180 : 0}
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                                losLocation,
                                direction,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        radius={radius * 1.33}
                        sides="3"
                        stroke={
                            isSelectedorHovered
                                ? theme.palette.primary.main
                                : shape.border && shape.color
                        }
                        strokeWidth={
                            isSelectedorHovered
                                ? radius / 3
                                : shape.border && radius / 7
                        }
                        fill={shape.fill === "full" && shape.color}
                        fillLinearGradientStartPoint={
                            shape.fill === "left" ||
                            shape.fill === "middle" ||
                            shape.fill === "right"
                                ? { x: -radius, y: 0 }
                                : undefined
                        }
                        fillLinearGradientEndPoint={
                            shape.fill === "left" ||
                            shape.fill === "middle" ||
                            shape.fill === "right"
                                ? { x: radius, y: 0 }
                                : undefined
                        }
                        fillLinearGradientColorStops={
                            shape.fill === "left"
                                ? [
                                      0,
                                      shape.color,
                                      0.5,
                                      shape.color,
                                      0.5,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : shape.fill === "right"
                                ? [
                                      0,
                                      "transparent",
                                      0.5,
                                      "transparent",
                                      0.5,
                                      shape.color,
                                      1,
                                      shape.color,
                                  ]
                                : shape.fill === "middle"
                                ? [
                                      0,
                                      "transparent",
                                      0.25,
                                      "transparent",
                                      0.25,
                                      shape.color,
                                      0.75,
                                      shape.color,
                                      0.75,
                                      "transparent",
                                      1,
                                      "transparent",
                                  ]
                                : []
                        }
                        onClick={(e) => onClick({ e, shape })}
                        onContextMenu={(e) => onContextMenu({ e, shape })}
                    />
                </>
            );
        } else if (shape.shape === "line") {

            const radius = (shape.radius * fieldDimensions.width) / 65;

            let points = shape.points.map((point, i) => {
                if (i % 2 === 0) {
                    // x coordinate
                    return fieldDimensions.width * (point / 65);
                } else {
                    // y coordinate
                    return fieldDimensions.height * (point / 150);
                }
            });
            const lastPoint = points.slice(-2);
            const secondLastPoint = points.slice(-4, -2);
            const angle = Math.atan2(
                lastPoint[1] - secondLastPoint[1],
                lastPoint[0] - secondLastPoint[0]
            );

            if (shape.head === "flat") {
                // Calculate perpendicular line
                const perpendicularAngle = angle + Math.PI / 2;
                const offsetX =
                    fieldDimensions.width *
                    (0.5 / 65) *
                    Math.cos(perpendicularAngle);
                const offsetY =
                    fieldDimensions.width *
                    (0.5 / 65) *
                    Math.sin(perpendicularAngle);

                if (!previewedShape || previewedShape.id === shape.id) {
                    points.push(
                        lastPoint[0] + offsetX,
                        lastPoint[1] + offsetY
                    ) &&
                        points.push(
                            lastPoint[0] - offsetX,
                            lastPoint[1] - offsetY
                        );
                }
            }
            let SHAPE = Line;
            if (shape.head === "arrow") {
                SHAPE = Arrow;
            }

            if (shape.jagged) {
                let jaggedPoints = [];
                for (let i = 0; i < points.length - 2; i += 2) {
                    const x1 = points[i];
                    const y1 = points[i + 1];
                    const x2 = points[i + 2];
                    const y2 = points[i + 3];
                    const segmentLength = Math.sqrt(
                        Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)
                    );
                    const numJags = Math.floor(segmentLength / 10); // Adjust the jaggedness by changing the divisor
                    const xIncrement = (x2 - x1) / numJags;
                    const yIncrement = (y2 - y1) / numJags;

                    for (let j = 0; j < numJags; j++) {
                        const x = x1 + xIncrement * j;
                        const y = y1 + yIncrement * j;
                        if (j % 2 === 0) {
                            jaggedPoints.push(x + 5, y + 5); // Adjust the jaggedness by changing the offset
                        } else {
                            jaggedPoints.push(x - 5, y - 5); // Adjust the jaggedness by changing the offset
                        }
                    }
                }
                jaggedPoints.push(
                    points[points.length - 2],
                    points[points.length - 1]
                );
                points = jaggedPoints;
            }

            return (
                <SHAPE
                    key={shape.id}
                    id={shape.id}
                    draggable={
                        state.editing &&
                        editingMode === "selecting" &&
                        draggable
                    }
                    dash={shape.dashed && [radius * 1, radius * 2]}
                    points={points}
                    stroke={
                        (state.editing && shape.selected) ||
                        shape.id === hoveredId
                            ? theme.palette.primary.main
                            : fill
                    }
                    onClick={(e) => onClick({ e, shape })}
                    onDragStart={(e) =>
                        handleDragStart({
                            e,
                            state,
                            setState,
                            setDragOrigin,
                            shape,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                            redoStack,
                            setRedoStack,
                            losLocation,
                            direction,
                        })
                    }
                    onDragMove={(e) =>
                        handleDragMove({
                            e,
                            shape,
                            dragOrigin,
                            draggingShapeId,
                            setDragOrigin,
                            fieldDimensions,
                            state,
                            setState,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    onDragEnd={() =>
                        handleDragEnd({
                            state,
                            setState,
                            setDragOrigin,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    strokeWidth={radius}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation="source-over"
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {state.shapes.length > 0 &&
                state.shapes.map((shape) =>
                    renderShape({
                        shape,
                        fill: shape.color,
                        draggable: shape.selected || shape.id === hoveredId,
                        undoStack,
                        setUndoStack,
                        redoStack,
                        setRedoStack,
                        isPreviewed: false,
                    })
                )}
            {Boolean(previewedShape) &&
                renderShape({
                    shape: previewedShape,
                    fill: previewedShape.fill,
                    draggable: false,
                    undoStack,
                    setUndoStack,
                    redoStack,
                    setRedoStack,
                    isPreviewed: true,
                })}
        </>
    );
};

export default Drawing;
