import React from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Typography,
    CircularProgress,
    TextField,
    Button,
    Alert,
} from "@mui/material";
import Card from "@mui/material/Card";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import OrganizationMembers from "./Members";
import OrganizationGroups from "./Groups";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserContext } from "../../UserContext";
import { useNavigate } from "react-router-dom";

export default function Organization() {
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        organization: {},
        isFetching: false,
        isFetched: false,
        didCopy: false,
    });
    const { user } = React.useContext(UserContext);
    if (
        user.isFetchedGroups &&
        !user.groups.some(
            (group) =>
                group.group.name === "Administrators" &&
                group.tenant.name === "clipr"
        )
    ) {
        navigate("/oops");
    }
    const params = useParams();
    const tenantId = params.tenantId;

    const fetchOrganization = async () => {
        setState({ ...state, isFetching: true });
        const restOperation = get({
            apiName: "user_management",
            path: `/tenants/get/${tenantId}`,
            options: {
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        setState({
            ...state,
            organization: json,
            isFetching: false,
            isFetched: true,
        });
    };

    if (!state.isFetching && !state.isFetched) {
        fetchOrganization();
    }
    const onCopy = () => {
        navigator.clipboard.writeText(
            `${window.location.origin}/tenant/${state.organization.id}/register/${state.organization.associationKey}`
        );
        setState((prevState) => ({ ...prevState, didCopy: true }));
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop="4vh"
            spacing={2}
        >
            {state.isFetched && (
                <Typography variant="h1">{state.organization.name}</Typography>
            )}
            {state.isFetching ? (
                <CircularProgress />
            ) : (
                <Card
                    style={{
                        padding: "2vh",
                        marginTop: "2vh",
                        width: "80vw",
                        maxWidth: "800px",
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="2vh"
                    >
                        <Typography variant="h4">
                            <b>Registration URL</b>
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                        >
                            <TextField
                                value={`${window.location.origin}/tenant/${state.organization.id}/register/${state.organization.associationKey}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                                style={{ width: "75%" }}
                            />
                            <Button onClick={onCopy}>
                                <ContentCopyIcon />
                            </Button>
                        </Box>
                        {state.didCopy && (
                            <Alert
                                severity="success"
                                style={{ width: "350px" }}
                                onClose={() => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        didCopy: false,
                                    }));
                                }}
                            >
                                Registration URL Copied to Clipboard
                            </Alert>
                        )}
                    </Box>
                </Card>
            )}
            <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                justifyContent="center"
                marginTop="4vh"
                gap="2vw"
                width="80vw"
                maxWidth={{ xs: "400px", md: "800px" }}
            >
                <OrganizationMembers tenant={state.organization} />
                <OrganizationGroups tenantId={tenantId} />
            </Box>
        </Box>
    );
}
