import React from "react";
import { MenuItem, Menu, Tooltip, Typography, Grid2 } from "@mui/material";
import CircleFillLeft from "../../../../../assets/CircleFillLeft.png";
import CircleFillMiddle from "../../../../../assets/CircleFillMiddle.png";
import CircleFillRight from "../../../../../assets/CircleFillRight.png";
import CircleFillFull from "../../../../../assets/CircleFillFull.png";
import CircleFillEmpty from "../../../../../assets/CircleFillEmpty.png";
import { IconCaretRightFilled } from "@tabler/icons-react";

export default function FillMenu({
    contextMenu,
    fillMenuAnchorEl,
    handleFillMenuClose,
    handleFillMenuOpen,
    state,
    setState,
}) {
    return (
        <MenuItem
            disabled={!contextMenu.shape}
            onMouseEnter={handleFillMenuOpen}
            onMouseLeave={handleFillMenuClose}
        >
            <Grid2
                container
                sx={{ width: "100%" }}
                display={"flex"}
                alignItems={"center"}
            >
                <Grid2 size={11}>
                    <Typography>Fill</Typography>
                </Grid2>
                <Grid2 size={1}>
                    <IconCaretRightFilled width="10px" height="10px" />
                </Grid2>
            </Grid2>
            <Tooltip>
                <span>
                    <Menu
                        disabled={!contextMenu.shape}
                        anchorEl={fillMenuAnchorEl}
                        open={Boolean(fillMenuAnchorEl)}
                        onClose={handleFillMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  fill: "empty",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <img
                                alt=""
                                src={CircleFillEmpty}
                                style={{ height: "24px" }}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  fill: "left",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <img
                                alt=""
                                src={CircleFillLeft}
                                style={{ height: "24px" }}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  fill: "middle",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <img
                                alt=""
                                src={CircleFillMiddle}
                                style={{ height: "24px" }}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  fill: "right",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <img
                                alt=""
                                src={CircleFillRight}
                                style={{ height: "24px" }}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  fill: "full",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <img
                                alt=""
                                src={CircleFillFull}
                                style={{ height: "24px" }}
                            />
                        </MenuItem>
                    </Menu>
                </span>
            </Tooltip>
        </MenuItem>
    );
}
