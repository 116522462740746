import React, { useState } from "react";
import {
    signUp,
    confirmSignUp,
    autoSignIn,
    resendSignUpCode,
} from "aws-amplify/auth";
import { Typography, Box, CircularProgress } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { UserContext } from "../../UserContext";
import CliprIcon from "../../assets/clipr-icon.png";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const { user, setUser } = React.useContext(UserContext);
    const navigate = useNavigate();

    if (user.isAuthorized) {
        navigate("/account");
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [formState, setFormState] = useState({
        didSignUp: false,
        isSigningUp: false,
        didSignUpError: false,
        didConfirmSignUp: false,
        isConfirmingSignUp: false,
        didConfirmSignUpError: false,
        signUpError: "",
    });

    let userObject = null;

    const handleSignUp = () => {
        setFormState({ ...formState, isSigningUp: true });
        signUp({
            username: email,
            password: password,
            options: {
                userAttributes: {
                    email: email,
                },
                autoSignIn: { enabled: true },
            },
        })
            .then((user) => {
                userObject = user;
                setFormState({
                    ...formState,
                    didSignUp: true,
                    isSigningUp: false,
                    didSignUpError: false,
                });
            })
            .catch((error) => {
                if (error.name === "UsernameExistsException") {
                    resendSignUpCode({
                        username: email,
                    });
                    setFormState({
                        ...formState,
                        didSignUp: true,
                        isSigningUp: false,
                        didSignUpError: false,
                    });
                } else {
                    setFormState({
                        ...formState,
                        didSignUp: false,
                        isSigningUp: false,
                        didSignUpError: true,
                        signUpError: error.name,
                    });
                }
            });
    };

    const handleConfirmSignUp = () => {
        setFormState({ ...formState, isConfirmingSignUp: true });
        confirmSignUp({
            confirmationCode,
            username: email,
        })
            .then(() => {
                setFormState({
                    ...formState,
                    didConfirmSignUp: true,
                    isConfirmingSignUp: false,
                    didConfirmSignUpError: false,
                });
                autoSignIn()
                    .then(() => {
                        setUser({
                            ...user,
                            cognito: userObject,
                            isAuthorized: true,
                            isAuthorizing: false,
                        });
                        navigate("/account");
                    })
                    .catch((error) => {
                        console.error("Auto sign-in failed:", error);
                    });
            })
            .catch((error) => {
                setFormState({
                    ...formState,
                    didConfirmSignUp: false,
                    isConfirmingSignUp: false,
                    didConfirmSignUpError: true,
                });
            });
    };

    return (
        <Box
            display="flex"
            marginTop="5%"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", maxWidth: "100vw", overflowX: "hidden" }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="1vh"
                paddingX="5vw"
                paddingBottom="3vh"
            >
                {formState.didSignUp && formState.didConfirmSignUp && (
                    <CircularProgress />
                )}
                {!formState.didConfirmSignUp && (
                    <>
                        <Box
                            display="flex"
                            flexDirection="row"
                            marginY="5%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                alt=""
                                src={CliprIcon}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginRight: "4px",
                                }}
                            />
                            <Typography
                                style={{
                                    textAlign: "center",
                                    fontSize: "50px",
                                    fontWeight: "bold",
                                }}
                            >
                                clipr
                            </Typography>
                        </Box>
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "25px",
                            }}
                        >
                            Sign Up
                        </Typography>
                        {!formState.didSignUp && (
                            <Box
                                marginTop="2%"
                                display="flex"
                                justifyContent="center"
                                width="100%"
                            >
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ width: "80vw", maxWidth: "400px" }}
                                />
                            </Box>
                        )}
                    </>
                )}

                {!formState.didSignUp && (
                    <Box display="flex" justifyContent="center" width="100%">
                        <TextField
                            label="Password"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                )}
                {formState.didSignUp && !formState.didConfirmSignUp && (
                    <Box
                        marginTop="2%"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <TextField
                            label="Confirmation Code"
                            variant="outlined"
                            margin="normal"
                            value={confirmationCode}
                            onChange={(e) =>
                                setConfirmationCode(e.target.value)
                            }
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                )}
                {formState.didSignUpError && (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Alert
                            severity="error"
                            style={{
                                width: "80vw",
                                maxWidth: "400px",
                                marginTop: "1vh",
                            }}
                        >
                            {formState.signUpError ===
                            "InvalidPasswordException"
                                ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                                : "An error occurred while signing up. Please try again."}
                        </Alert>
                    </Box>
                )}
                {formState.didConfirmSignUpError && (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Alert
                            severity="error"
                            style={{
                                width: "80vw",
                                maxWidth: "400px",
                                marginTop: "1vh",
                            }}
                        >
                            An error occurred while confirming sign up. Please
                            try again.
                        </Alert>
                    </Box>
                )}
                {!formState.didConfirmSignUp && (
                    <Box display="flex" justifyContent="center" width="100%">
                        {formState.isSigningUp ||
                        formState.isConfirmingSignUp ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    margin: "10px",
                                    width: "80vw",
                                    maxWidth: "400px",
                                }}
                                onClick={
                                    !formState.didSignUp
                                        ? handleSignUp
                                        : handleConfirmSignUp
                                }
                            >
                                Continue
                            </Button>
                        )}
                    </Box>
                )}
                {!formState.didConfirmSignUp && (
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "16px",
                            marginTop: "1vh",
                        }}
                    >
                        Already have an account?
                        <Link
                            to="/sign-in"
                            style={{ color: "#095E89", textDecoration: "none" }}
                        >
                            Sign In
                        </Link>
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default SignUp;
