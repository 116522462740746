import React, { useState } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import ButtonBase from "@mui/material/ButtonBase";
import { UserContext } from "../../UserContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Organizations = () => {
    const { user } = React.useContext(UserContext);
    const navigate = useNavigate();

    const [organizations, setOrganizations] = useState([]);
    const [formState, setFormState] = useState({
        isFetching: false,
        isFetched: false,
    });

    const fetchOrganizations = async () => {
        setFormState({ ...formState, isFetching: true });
        const restOperation = get({
            apiName: "user_management",
            path: "/tenants/list",
            options: {
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        setOrganizations(json);
        setFormState({ ...formState, isFetching: false, isFetched: true });
    };

    if (
        !formState.isFetching &&
        !formState.isFetched &&
        user.isFetchedGroups &&
        user.groups.some(
            (group) =>
                group.group.name === "Administrators" &&
                group.tenant.name === "clipr"
        )
    ) {
        fetchOrganizations();
    }

    if (
        user.isFetchedGroups &&
        !user.groups.some(
            (group) =>
                group.group.name === "Administrators" &&
                group.tenant.name === "clipr"
        )
    ) {
        navigate("/oops");
    }

    return (
        <Box>
            {user.isFetchingGroups && <CircularProgress />}
            {user.isFetchedGroups &&
                user.groups.some(
                    (group) =>
                        group.group.name === "Administrators" &&
                        group.tenant.name === "clipr"
                ) && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        paddingTop="30px"
                    >
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                            marginRight="3vh"
                        >
                            <Link to="/create-tenant">
                                <Button variant="contained" color="primary">
                                    <Typography
                                        style={{
                                            flexGrow: 1,
                                            textAlign: "center",
                                            fontSize: "18px",
                                            color: "#003459",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Create Tenant
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            marginTop="4vh"
                        ></Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            marginBottom="3vh"
                        >
                            <Typography variant="h4">
                                <b>Organizations</b>
                            </Typography>
                        </Box>
                        {formState.isFetching && <CircularProgress />}
                        {formState.isFetched && organizations.length > 0 && (
                            <Box width="80vw" maxWidth="400px">
                                {organizations.map((organization, index) => (
                                    <ButtonBase
                                        key={organization.id}
                                        onClick={() =>
                                            navigate(
                                                `/tenant/${organization.id}`
                                            )
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        <Grid
                                            key={organization.id}
                                            container
                                            spacing={2}
                                            width="100%"
                                        >
                                            <Box
                                                key={organization.id}
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="left"
                                                justifyContent="left"
                                                width="100%"
                                                borderBottom="1px solid grey"
                                            >
                                                <Grid
                                                    size={12}
                                                    key={organization.id}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="left"
                                                        justifyContent="left"
                                                        key={organization.id}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            key={
                                                                organization.id
                                                            }
                                                        >
                                                            <b>
                                                                {
                                                                    organization.name
                                                                }
                                                            </b>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </ButtonBase>
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default Organizations;
