import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
export default function ConfirmSave({ savePlay, setSaveState, playId }) {
    return (
        <Modal open={true} onClose={() => setSaveState("idle")}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    border: "2px solid black",
                    width: playId === "new" ? 400 : 600,
                    height: 150,
                    boxShadow: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    width={600}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"center"}
                >
                    <Box
                        display="flex"
                        flexDirection={"row"}
                        justifyContent="space-around"
                    >
                        {playId !== "new" && (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    savePlay("overwrite");
                                }}
                            >
                                <Typography variant="h6">Overwrite</Typography>
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            onClick={() => {
                                savePlay("new");
                            }}
                        >
                            <Typography variant="h6">Save as New</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
