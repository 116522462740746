import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { UserContext } from "../../UserContext";
import { Typography } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";
import SideNav from "./SideNav";
import AccountDropDown from "./AccountDropDown";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function MenuBar() {
    const { user, setUser } = React.useContext(UserContext);
    const [isNavOpen, setIsNavOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleLogin = () => {
        navigate("/sign-in");
    };

    const handleSignUp = () => {
        navigate("/sign-up");
    };

    const handleSetNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLogOut = async () => {
        try {
            await signOut();
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            navigate("/sign-in");
        } catch (error) {
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            navigate("/sign-in");
        }
    };

    if (
        user.isAuthorized &&
        !user.isFetchingAttributes &&
        !user.attributes.isAccountComplete &&
        !["/account", "/"].includes(window.location.pathname)
    ) {
        window.location = "/account";
    }

    const currentPath = window.location.pathname;

    const isCliprAdmin = user.groups.some(
        (group) =>
            group.group.name === "Administrators" &&
            group.tenant.name === "clipr"
    );

    const [anchorEl, setAnchorEl] = React.useState({
        root: null,
        organization: null,
    });
    const theme = useTheme();
    return (
        <Box sx={{ flexGrow: 1 }} marginBottom="72px">
            <AppBar
                position="fixed"
                sx={{
                    bgcolor: "background.default",
                    zIndex: theme.zIndex.drawer + 1,
                    top: "0",
                }}
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleSetNavOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <SideNav
                        isNavOpen={isNavOpen}
                        isCliprAdmin={isCliprAdmin}
                        handleSetNavOpen={handleSetNavOpen}
                    />
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <img
                                src={CliprIcon}
                                alt=""
                                style={{
                                    flexGrow: 1,
                                    height: "34px",
                                    marginRight: "2px",
                                }}
                            />
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    color: "black",
                                }}
                            >
                                <b>clipr</b>
                            </Typography>
                        </IconButton>
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    {!user.isAuthorized && currentPath !== "/sign-up" && (
                        <IconButton
                            onClick={handleSignUp}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign up
                            </MenuItem>
                        </IconButton>
                    )}
                    {!user.isAuthorized && currentPath !== "/sign-in" && (
                        <IconButton
                            onClick={handleLogin}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign in
                            </MenuItem>
                        </IconButton>
                    )}
                    <AccountDropDown
                        user={user}
                        setUser={setUser}
                        handleLogOut={handleLogOut}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    />
                </Toolbar>
            </AppBar>
        </Box>
    );
}
