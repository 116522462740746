import React from "react";
import { Box, Button, Grid2 as Grid } from "@mui/material";
import { IconFolder } from "@tabler/icons-react";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { UserContext } from "../../../UserContext";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { IconTrash } from "@tabler/icons-react";

export default function FileExplorer({ stageDimensions, state, playId }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const { user } = React.useContext(UserContext);
    const [files, setFiles] = React.useState([]);
    const [fetchState, setFetchState] = React.useState("idle");

    async function fetchFiles() {
        if (!user.activeOrganization) return null;

        setFetchState("fetching");
        const restOperation = get({
            apiName: "playbook",
            path: "/list",
            options: {
                queryParams: {
                    tenantId: user.activeOrganization.id,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const json = await response.body.json();
        setFiles(json);
        setFetchState("fetched");
    }

    if (fetchState === "idle") {
        fetchFiles();
    }

    if (fetchState === "idle" && playId !== "new") {
        fetchFiles();
    }

    if (playId !== "new" && playId !== state.id && fetchState === "fetched") {
        fetchFiles();
    }

    return (
        <Box
            position="absolute"
            top="0"
            left="0"
            zIndex={1200} // Ensure it sits on top of the field but below the AppBar
            width={open ? stageDimensions.width * 0.75 : 64}
            maxWidth={stageDimensions.width * 0.95}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="left"
            bgcolor="#D3D4D8"
            overflow={open ? "visible" : "hidden"} // Allow overflow when open
            marginLeft={"-64px"}
            border="1px solid black"
        >
            <Box
                width="64px"
                borderBottom="1px solid black"
                borderRight={open ? "1px solid black" : "none"}
            >
                <Button
                    onClick={() => setOpen(!open)}
                    variant="outlined"
                    style={{
                        bgcolor: "grey",
                        width: "64px",
                        height: "64px",
                        color: "black",
                        borderWidth: "0px",
                    }}
                >
                    <IconFolder />
                </Button>
            </Box>
            {open && (
                <Box
                    width="100% - 64px"
                    height={stageDimensions.height - 64}
                    overflow="scroll"
                    bgcolor="#e6e7eb"
                    marginLeft="64px"
                >
                    <SimpleTreeView>
                        {files &&
                            files.map((file) => {
                                let label = `${file.formation} ${file.play}${
                                    file.vsFormation || file.vsPlay
                                        ? " vs "
                                        : ""
                                }${file.vsFormation} ${file.vsPlay}`.replace(
                                    / +(?= )/g,
                                    ""
                                );
                                if (label === " ") {
                                    label = "Untitled";
                                }
                                return (
                                    file.playbookId && (
                                        <Grid container>
                                            <Grid size={11.5}>
                                                <TreeItem
                                                    itemId={file.playbookId}
                                                    label={label}
                                                    onClick={() => {
                                                        navigate(
                                                            `/play-designer/${file.playbookId}`
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                size={0.5}
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <IconButton color="error">
                                                    <IconTrash />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                );
                            })}
                    </SimpleTreeView>
                </Box>
            )}
        </Box>
    );
}
