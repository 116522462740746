import React from "react";
import { MenuItem, Menu, Box, Tooltip, Typography, Grid2 } from "@mui/material";
import { IconCaretRightFilled } from "@tabler/icons-react";

export default function ColorMenu({
    contextMenu,
    colorMenuAnchorEl,
    handleColorMenuClose,
    handleColorMenuOpen,
    state,
    setState,
}) {
    const colorOptions = [
        "black",
        "white",
        "red",
        "blue",
        "green",
        "yellow",
        "purple",
        "orange",
        "pink",
        "brown",
        "gray",
    ];
    return (
        <MenuItem
            disabled={!contextMenu.shape}
            onMouseEnter={handleColorMenuOpen}
            onMouseLeave={handleColorMenuClose}
        >
            <Grid2
                container
                sx={{ width: "100%" }}
                display={"flex"}
                alignItems={"center"}
            >
                <Grid2 size={11}>
                    <Typography>Color</Typography>
                </Grid2>
                <Grid2 size={1}>
                    <IconCaretRightFilled width="10px" height="10px" />
                </Grid2>
            </Grid2>
            <Tooltip>
                <span>
                    <Menu
                        disabled={!contextMenu.shape}
                        anchorEl={colorMenuAnchorEl}
                        open={Boolean(colorMenuAnchorEl)}
                        onClose={handleColorMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {colorOptions.map((color) => (
                            <MenuItem
                                onClick={() =>
                                    setState({
                                        ...state,
                                        shapes: state.shapes.map((shape) => {
                                            return shape.id ===
                                                contextMenu.shape.id
                                                ? {
                                                      ...shape,
                                                      color: color,
                                                  }
                                                : shape;
                                        }),
                                    })
                                }
                            >
                                <Box
                                    height="24px"
                                    width="24px"
                                    border={
                                        color === "white"
                                            ? "1px solid black"
                                            : ""
                                    }
                                    bgcolor={color}
                                />
                            </MenuItem>
                        ))}
                    </Menu>
                </span>
            </Tooltip>
        </MenuItem>
    );
}
