import {
    Box,
    Button,
    ButtonGroup,
    MenuItem,
    Menu,
    Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {
    IconLine,
    IconPointer,
    IconCircleSquare,
    IconLineDashed,
    IconArrowNarrowRightDashed,
    IconArrowNarrowRight,
    IconLetterT,
    IconLetterI,
    IconCircle,
    IconSquare,
    IconTriangle,
    IconTrash,
    IconArrowsLeftRight,
    IconArrowsDownUp,
} from "@tabler/icons-react";
import { useState } from "react";
import DashedFlatArrow from "../../../assets/dashed-flat-arrow.png";
import ZigZagLine from "../../../assets/zigzag-line.png";
import { v4 as uuidv4 } from "uuid";
import { flipPlayHorizontal, flipPlayVertical } from "./flipPlay";
// import { fetchAuthSession } from "aws-amplify/auth";
// import { post } from "aws-amplify/api";

export default function EditMenu({
    parentState,
    setParentState,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    setPreviewedShape,
    isDrawing,
    isAddingLine,
    anchoredPreviewedLine,
    losLocation,
    setLosLocation,
    direction,
}) {
    const resetControls = () => {
        setPreviewedShape(null);
        isDrawing.current = false;
        isAddingLine.current = false;
        anchoredPreviewedLine.current = false;
        setParentState({
            ...parentState,
            editingMode: "selecting",
            addingVariant: null,
        });
    };

    const handleUndo = () => {
        resetControls();
        if (undoStack.length > 0) {
            const lastState = undoStack.pop();
            setUndoStack([...undoStack]);
            setRedoStack([
                ...redoStack,
                {
                    shapes: parentState.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: parentState.formation,
                    play: parentState.play,
                    vsFormation: parentState.vsFormation,
                    vsPlay: parentState.vsPlay,
                    notes: parentState.notes,
                },
            ]);
            direction.current = lastState.direction;
            setLosLocation(lastState.losLocation);
            setParentState({
                ...parentState,
                shapes: lastState.shapes,
                notes: lastState.notes,
                formation: lastState.formation,
                play: lastState.play,
                vsFormation: lastState.vsFormation,
                vsPlay: lastState.vsPlay,
            });
        }
    };

    const handleRedo = () => {
        resetControls();
        if (redoStack.length > 0) {
            const lastState = redoStack.pop();
            setRedoStack([...redoStack]);
            setUndoStack([
                ...undoStack,
                {
                    shapes: parentState.shapes,
                    direction: direction.current,
                    losLocation,
                    formation: parentState.formation,
                    play: parentState.play,
                    vsFormation: parentState.vsFormation,
                    vsPlay: parentState.vsPlay,
                    notes: parentState.notes,
                },
            ]);
            direction.current = lastState.direction;
            setLosLocation(lastState.losLocation);
            setParentState({
                ...parentState,
                shapes: lastState.shapes,
            });
        }
    };

    const [dropdown, setDropdown] = useState(null);

    return (
        parentState.editing && (
            <Grid
                container
                spacing={2}
                display="flex"
                flexDirection="row"
                justifyContent="left"
                alignItems="left"
                width={{
                    xs: "90vw",
                    sm: "600px",
                    md: "900px",
                    lg: "1200px",
                    xl: "1500px",
                }}
                height="25px"
            >
                <>
                    <Grid
                        size={4}
                        display="flex"
                        flexDirection="row"
                        justifyContent="left"
                        alignItems="left"
                    >
                        <ButtonGroup // undo/redo
                            sx={{ height: "100%", width: "80%" }}
                        >
                            <Tooltip title="Undo">
                                <span>
                                    <Button
                                        variant="contained"
                                        sx={{ border: "0.5px solid black" }}
                                        disabled={!undoStack.length > 0}
                                        onClick={handleUndo}
                                    >
                                        <UndoIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Redo">
                                <span>
                                    <Button
                                        variant="contained"
                                        sx={{ border: "0.5px solid black" }}
                                        disabled={!redoStack.length > 0}
                                        onClick={handleRedo}
                                    >
                                        <RedoIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                    <Grid size={3}>
                        <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                            <Tooltip title="Select Mode">
                                <span>
                                    <Button
                                        variant="contained"
                                        sx={{ border: "0.5px solid black" }}
                                        onClick={() => {
                                            setParentState({
                                                ...parentState,
                                                editingMode: "selecting",
                                            });
                                            resetControls();
                                        }}
                                    >
                                        <IconPointer />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Box
                                display="flex"
                                flexDirection="column"
                                position="relative"
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        border: "0.5px solid black",
                                        height: "100%",
                                    }}
                                    onMouseEnter={(e) => {
                                        setDropdown({
                                            location: e.currentTarget,
                                            name: "line",
                                        });
                                        resetControls();
                                    }}
                                >
                                    <IconLine />
                                </Button>
                                <Menu
                                    anchorEl={dropdown && dropdown.location}
                                    open={Boolean(
                                        dropdown && dropdown.name === "line"
                                    )}
                                    onClose={() => setDropdown(null)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <MenuItem // regular line
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: false,
                                                    head: null,
                                                },
                                            });
                                        }}
                                    >
                                        <IconLetterI
                                            style={{
                                                transform: "rotate(90deg)",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem // flathead
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: false,
                                                    head: "flat",
                                                },
                                            });
                                        }}
                                    >
                                        <IconLetterT
                                            style={{
                                                transform: "rotate(90deg)",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem // arrow
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: false,
                                                    head: "arrow",
                                                    jagged: false,
                                                },
                                            });
                                        }}
                                    >
                                        <IconArrowNarrowRight />
                                    </MenuItem>
                                    <MenuItem //dashed line
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: true,
                                                    head: null,
                                                },
                                            });
                                        }}
                                    >
                                        <IconLineDashed style={{}} />
                                    </MenuItem>
                                    <MenuItem //dashed flathead
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: true,
                                                    head: "flat",
                                                },
                                            });
                                        }}
                                    >
                                        <img
                                            alt=""
                                            src={DashedFlatArrow}
                                            style={{ height: "24px" }}
                                        />
                                    </MenuItem>
                                    <MenuItem // dashed arrow head
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: true,
                                                    head: "arrow",
                                                    jagged: false,
                                                },
                                            });
                                        }}
                                    >
                                        <IconArrowNarrowRightDashed />
                                    </MenuItem>
                                    <MenuItem // jagged line
                                        onClick={() => {
                                            resetControls();
                                            setDropdown(null);
                                            // isAddingLine.current = true;
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingLine",
                                                addingVariant: {
                                                    dashed: false,
                                                    head: null,
                                                    jagged: true,
                                                },
                                            });
                                        }}
                                    >
                                        <img
                                            alt=""
                                            src={ZigZagLine}
                                            style={{ height: "24px" }}
                                        />
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Button
                                variant="contained"
                                sx={{ border: "0.5px solid black" }}
                                onMouseEnter={(e) =>
                                    setDropdown({
                                        location: e.currentTarget,
                                        name: "shape",
                                    })
                                }
                            >
                                <IconCircleSquare />
                                <Menu
                                    anchorEl={dropdown && dropdown.location}
                                    open={Boolean(
                                        dropdown && dropdown.name === "shape"
                                    )}
                                    onClose={() => setDropdown(null)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setDropdown(null);
                                            isDrawing.current = false;
                                            isAddingLine.current = false;
                                            setPreviewedShape({
                                                shape: "circle",
                                                id: uuidv4(),
                                                selected: false,
                                                x: 0,
                                                y: 0,
                                                border: true,
                                                label: "",
                                                fill: "empty",
                                                color: "black",
                                                radius: 0.625,
                                            });
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingIcon",
                                            });
                                        }}
                                    >
                                        <IconCircle />
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => {
                                            setDropdown(null);
                                            isDrawing.current = false;
                                            isAddingLine.current = false;
                                            setPreviewedShape({
                                                shape: "rect",
                                                id: uuidv4(),
                                                selected: false,
                                                x: 0,
                                                y: 0,
                                                border: true,
                                                label: "",
                                                fill: "empty",
                                                color: "black",
                                                radius: 0.625,
                                            });
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingIcon",
                                            });
                                        }}
                                    >
                                        <IconSquare />
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => {
                                            setDropdown(null);
                                            isDrawing.current = false;
                                            isAddingLine.current = false;
                                            setPreviewedShape({
                                                shape: "triangle",
                                                id: uuidv4(),
                                                selected: false,
                                                x: 0,
                                                y: 0,
                                                border: true,
                                                label: "",
                                                fill: "empty",
                                                color: "black",
                                                radius: 0.625,
                                            });
                                            setParentState({
                                                ...parentState,
                                                editingMode: "addingIcon",
                                            });
                                        }}
                                    >
                                        <IconTriangle />
                                    </MenuItem>
                                </Menu>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid size={2}>
                        <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                            <Tooltip title="Flip Play Horizontally">
                                <span>
                                    <Button
                                        variant="contained"
                                        sx={{ border: "0.5px solid black" }}
                                        onClick={() => {
                                            setUndoStack([
                                                ...undoStack,
                                                {
                                                    shapes: parentState.shapes,
                                                    direction:
                                                        direction.current,
                                                    losLocation,
                                                    formation:
                                                        parentState.formation,
                                                    play: parentState.play,
                                                    vsFormation:
                                                        parentState.vsFormation,
                                                    vsPlay: parentState.vsPlay,
                                                    notes: parentState.notes,
                                                },
                                            ]);
                                            setRedoStack([]);
                                            setParentState({
                                                ...parentState,
                                                shapes: flipPlayHorizontal({
                                                    shapes: parentState.shapes,
                                                }),
                                            });
                                        }}
                                    >
                                        <IconArrowsLeftRight />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Flip Play Vertically">
                                <span>
                                    <Button
                                        variant="contained"
                                        sx={{ border: "0.5px solid black" }}
                                        onClick={() => {
                                            setUndoStack([
                                                ...undoStack,
                                                {
                                                    shapes: parentState.shapes,
                                                    direction:
                                                        direction.current,
                                                    losLocation,
                                                    formation:
                                                        parentState.formation,
                                                    play: parentState.play,
                                                    vsFormation:
                                                        parentState.vsFormation,
                                                    vsPlay: parentState.vsPlay,
                                                    notes: parentState.notes,
                                                },
                                            ]);
                                            setRedoStack([]);
                                            setParentState({
                                                ...parentState,
                                                shapes: flipPlayVertical({
                                                    shapes: parentState.shapes,
                                                    losLocation,
                                                    setLosLocation,
                                                    direction,
                                                }),
                                            });
                                        }}
                                    >
                                        <IconArrowsDownUp />
                                    </Button>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                    <Grid size={2} />
                    <Grid
                        size={1}
                        display="flex"
                        alignItems="right"
                        justifyContent="right"
                    >
                        <ButtonGroup
                            sx={{
                                height: "100%",
                                width: "80%",
                            }}
                        >
                            <Tooltip
                                title="Delete Selected"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    alignItems: "right",
                                    justifyContent: "right",
                                }}
                            >
                                <span
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        alignItems: "right",
                                        justifyContent: "right",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            border: "0.5px solid",
                                            borderColor: "black",
                                        }}
                                        disabled={
                                            parentState.shapes.filter(
                                                (shape) => shape.selected
                                            ).length === 0
                                        }
                                        onClick={() => {
                                            resetControls();

                                            setUndoStack([
                                                ...undoStack,
                                                {
                                                    shapes: parentState.shapes,
                                                    direction:
                                                        direction.current,
                                                    losLocation,
                                                    formation:
                                                        parentState.formation,
                                                    play: parentState.play,
                                                    vsFormation:
                                                        parentState.vsFormation,
                                                    vsPlay: parentState.vsPlay,
                                                    notes: parentState.notes,
                                                },
                                            ]);
                                            setRedoStack([]);
                                            setParentState({
                                                ...parentState,
                                                shapes: parentState.shapes.filter(
                                                    (shape) => {
                                                        if (shape.selected) {
                                                            return false;
                                                        }

                                                        return true;
                                                    }
                                                ),
                                            });
                                        }}
                                    >
                                        <IconTrash />
                                    </Button>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </>
            </Grid>
        )
    );
}
