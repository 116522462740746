import { Box,  Drawer, Typography } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";
import { Link } from "react-router-dom";

export default function SideNav({ isNavOpen, isCliprAdmin, handleSetNavOpen }) {
    return (
        <>
            <Drawer
                open={isNavOpen}
                onClose={handleSetNavOpen}
                PaperProps={{
                    sx: {
                        minWidth: "350px", // Customize the width of the drawer here
                        marginTop: "64px",
                    },
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    justifyContent="left"
                    width="100%"
                >
                    <Link
                        to="/"
                        style={{
                            alignItems: "left",
                            justifyContent: "left",
                            width: "100%",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        <Box display="flex" flexDirection="row">
                            <img
                                src={CliprIcon}
                                alt=""
                                style={{
                                    height: "34px",
                                    marginRight: "2px",
                                }}
                            />
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ fontFamily: "inherit" }}
                            >
                                <b>clipr</b>
                            </Typography>
                        </Box>
                    </Link>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    justifyContent="left"
                    width="100%"
                >
                    <Link
                        to="/play-designer/new"
                        style={{
                            alignItems: "left",
                            justifyContent: "left",
                            width: "100%",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        <Box display="flex" flexDirection="row">
                            <img
                                src={CliprIcon}
                                alt=""
                                style={{
                                    height: "34px",
                                    marginRight: "2px",
                                }}
                            />
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ fontFamily: "inherit" }}
                            >
                                playbook designer
                            </Typography>
                        </Box>
                    </Link>

                    <Link
                        to="/film/upload"
                        style={{
                            alignItems: "left",
                            justifyContent: "left",
                            width: "100%",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        <Box display="flex" flexDirection="row">
                            <img
                                src={CliprIcon}
                                alt=""
                                style={{
                                    height: "34px",
                                    marginRight: "2px",
                                }}
                            />
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ fontFamily: "inherit" }}
                            >
                                upload film
                            </Typography>
                        </Box>
                    </Link>
                    <Link
                        to="/account"
                        style={{
                            alignItems: "left",
                            justifyContent: "left",
                            width: "100%",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        <Box display="flex" flexDirection="row">
                            <img
                                src={CliprIcon}
                                alt=""
                                style={{
                                    height: "34px",
                                    marginRight: "2px",
                                }}
                            />
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ fontFamily: "inherit" }}
                            >
                                account
                            </Typography>
                        </Box>
                    </Link>
                    {isCliprAdmin && (
                        <Link
                            to="/tenants"
                            style={{
                                alignItems: "left",
                                justifyContent: "left",
                                width: "100%",
                                paddingLeft: "10px",
                                paddingTop: "10px",
                                textDecoration: "none",
                                color: "black",
                            }}
                        >
                            <Box display="flex" flexDirection="row">
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    tenants
                                </Typography>
                            </Box>
                        </Link>
                    )}
                </Box>
            </Drawer>
        </>
    );
}
