import React, { useState } from "react";
import { Typography, Box, Card } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const CreateOrganization = () => {
    const [orgName, setOrgName] = useState("");
    const [formState, setFormState] = useState({
        isSaving: false,
        isSaved: false,
    });
    const navigate = useNavigate();
    const handleCreateOrganization = async () => {
        setFormState({ ...formState, isSaving: true });
        const restOperation = post({
            apiName: "user_management",
            path: "/tenants/create",
            options: {
                body: {
                    name: orgName,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        const { body } = await response;
        const json = await body.json();
        navigate(`/tenant/${json.id}`);
    };

    return (
        <Box
            display="flex"
            marginY="200px"
            flexDirection="column"
            alignItems="center"
            style={{ minHeight: "100vh", minWidth: "100vh" }}
        >
            <Card>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingTop="1vh"
                    paddingBottom="3vh"
                    style={{
                        minWidth: "550px",
                    }}
                >
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        create organization
                    </Typography>

                    <Box
                        marginTop="3vh"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <TextField
                            label="Organization Name"
                            variant="outlined"
                            margin="normal"
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            style={{ width: "70%" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            variant="contained"
                            style={{ margin: "10px", width: "70%" }}
                            onClick={handleCreateOrganization}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default CreateOrganization;
